<template>
  <div class="page article-page">
    <div class="row">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm head">
        <div class="image">
          <image-elem :file="$store.state.page.content.pageimage.value" size="blog.detail" />
        </div>
        <h1 class="typo">{{ $store.state.page.content.title.value }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm text">
        <text-elem :text="abstract"></text-elem>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm text">
        <hr />
      </div>
    </div>
    <sections-elem :content="$store.state.page.content.sections"></sections-elem>
  </div>
</template>

<script>
export default {
  name: 'article-page',
  computed: {
    abstract () {
      var abstract = this.$store.state.page.content.abstract.value[0].html
      var author = this.$store.state.page.content.author.value || 'Redaktion'
      return {
        value: [{
          tag: 'p',
          html: abstract + ' Von <span class="author">' + author + '.</span>'
        }],
        type: 'html'
      }
    },
  }
}
</script>

<style lang="sass">
.article-page
  .head
    position: relative
    .image
      margin-bottom: m(2)
    h1
      position: absolute
      width: 80%
      top: 50%
      left: 10%
      transform: translateY(-50%)
      text-align: center
      +font('bold', 'jumbo')
      color: $white
  .author
    +font('bold')

+sm
  .article-page
    .head
      h1
        +font('bold', 'bigger')

+xs
  .article-page
    .head
      h1
        position: relative
        width: 100%
        top: auto
        left: auto
        transform: none
        text-align: left
        color: $text-color
</style>